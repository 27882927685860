* {
  box-sizing: border-box; }

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: #fafafa; }

.splash-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%; }

.main-action-fab {
  position: fixed !important;
  bottom: 30px;
  right: 30px; }

.booking-list.active,
.company-list.active {
  background-color: rgba(0, 0, 0, 0.14); }

.container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto; }

.hide-actions:hover + .hide-on-hover {
  display: block !important; }

.hide-on-hover {
  display: none; }
  .hide-on-hover:hover {
    display: block; }

.delMsg:hover {
  cursor: pointer; }

.login {
  min-width: 100vw;
  min-height: 100vh;
  display: flex; }
  .login .image {
    display: none;
    background-size: cover;
    background-position: center;
    flex: 1; }
    @media (min-width: 768px) {
      .login .image {
        display: block; } }
    @media (min-width: 1280px) {
      .login .image {
        display: block;
        flex: 0.75; } }
  .login .content-area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: column;
    background-color: #fff;
    border-radius: 6px;
    padding: 60px 20px 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25); }
    @media (min-width: 768px) {
      .login .content-area {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }
    @media (min-width: 1280px) {
      .login .content-area {
        position: static;
        transform: none;
        padding: 20px;
        box-shadow: none; } }
  .login .form {
    padding: 20px 0;
    max-width: 420px;
    display: flex;
    width: 100%;
    flex-direction: column; }
    @media (min-width: 768px) {
      .login .form {
        width: 100vw; } }

.booking .DateRangePicker {
  width: 100%; }
  .booking .DateRangePicker .DateRangePickerInput {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .booking .DateRangePicker .DateRangePickerInput .DateInput_input {
      font-size: 16px;
      font-weight: 500;
      text-align: center; }

.office .office-list {
  padding: 24px;
  max-width: 1000px;
  margin: 0 auto; }

.office-detail .hero,
.booking-summary .hero {
  width: 100%;
  height: 50vw;
  max-height: 250px;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc; }

.office-detail .details,
.booking-summary .details {
  padding: 48px 24px;
  max-width: 1200px;
  margin: 0 auto; }

.office-detail .spacer,
.booking-summary .spacer {
  margin: 24px 0; }

.admin .add-member {
  padding: 16px; }

.admin .active {
  background-color: rgba(0, 0, 0, 0.14); }

.resources {
  width: 100vw;
  overflow-y: auto;
  margin: 0 auto;
  padding: 32px 0; }
  @media (min-width: 960px) {
    .resources {
      overflow: auto;
      width: auto; } }
  .resources-inner {
    margin: 0 auto;
    max-width: 920px;
    min-width: 700px;
    padding: 16px; }
    @media (min-width: 960px) {
      .resources-inner {
        min-width: 0px; } }

@media (min-width: 960px) {
  .generate-report-modal {
    height: 400px; } }

.input-base-order.invalid input {
  border: 1px solid red; }

.input-base-order input {
  text-align: center;
  border-radius: 6px; }
  .input-base-order input:focus, .input-base-order input:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 6px; }

.file-attachment-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: block;
  position: static;
  overflow: hidden; }

.remove-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #666;
  border-radius: 6px;
  z-index: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px; }
  .remove-icon:hover {
    background-color: black; }
  .remove-icon span {
    color: #fff;
    display: block;
    position: relative;
    left: 1px;
    line-height: 0.9;
    font-size: 18px; }

.image-uploader {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .image-uploader .fileContainer {
    box-shadow: none; }

.ReactVirtualized__Grid {
  outline: none; }

.icon-picker {
  padding: 24px;
  position: relative; }
  .icon-picker-grid {
    height: 500px;
    background-color: #f2f2f2;
    border-radius: 6px;
    padding: 16px;
    margin: 0px -24px -24px !important; }
  .icon-picker-image {
    padding: 10px !important;
    cursor: pointer; }
    .icon-picker-image:hover {
      background-color: #fff;
      border-radius: 6px; }

.image-gallery {
  background-color: #f2f2f2;
  margin: 0px !important;
  padding: 12px !important; }
  .image-gallery-grid {
    max-height: 500px; }
  .image-gallery .gallery-image {
    position: relative;
    cursor: pointer; }
    .image-gallery .gallery-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .image-gallery .gallery-image:before {
      content: '';
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      user-select: none;
      z-index: 10; }
    .image-gallery .gallery-image:hover:before {
      border: 4px solid #3e9aff; }
    .image-gallery .gallery-image.selected:after {
      position: absolute;
      bottom: 8px;
      right: 8px;
      z-index: 1;
      user-select: none;
      content: 'check_circle';
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      color: #007aff;
      background-color: #fff;
      border-radius: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased; }
    .image-gallery .gallery-image.selected:before {
      border: 4px solid #007aff; }

.resources-public {
  font-family: 'Lato', sans-serif;
  background-color: #fff;
  width: 100%;
  height: 100%; }

.directory {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
  margin-top: 0px; }
  @media (min-width: 768px) {
    .directory {
      margin-top: 20px; } }
  @media (min-width: 960px) {
    .directory {
      padding: 0 8px;
      margin-top: 40px; } }
  .directory .search-bar-container {
    position: relative; }
    @media (min-width: 768px) {
      .directory .search-bar-container {
        max-width: 400px; } }
    .directory .search-bar-container .results-container {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%; }

.filter-header {
  padding: 0;
  margin-bottom: 20px;
  z-index: 101;
  position: relative; }
  @media (min-width: 768px) {
    .filter-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;
      padding-right: 16px; } }
  .filter-header .search-bar-placeholder {
    flex: 1; }
  .filter-header .search-bar-component {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1; }
    @media (min-width: 768px) {
      .filter-header .search-bar-component {
        left: 16px;
        right: auto;
        width: 50%;
        max-width: 400px; } }
  .filter-header .button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .filter-header .button-group .flex {
      flex: 0 0 100%; }
      @media (min-width: 768px) {
        .filter-header .button-group .flex {
          flex: auto; } }
    .filter-header .button-group button {
      flex: 0 0 48%;
      margin-top: 16px; }
      @media (min-width: 768px) {
        .filter-header .button-group button {
          flex: auto;
          margin-right: 0;
          margin-left: 16px; } }

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 16px; }
  @media (min-width: 960px) {
    .top-bar {
      padding: 0px 16px; } }
  .top-bar .search-bar {
    flex: 1;
    display: flex;
    align-items: center; }
    @media (min-width: 960px) {
      .top-bar .search-bar input {
        font-weight: 500;
        font-size: 18px; } }

.count-pill {
  background-color: #e0e0e0;
  border-radius: 10px;
  padding: 0 4px;
  display: block;
  margin-left: 4px;
  margin-right: 4px;
  white-space: nowrap; }
  .count-pill.pending {
    background-color: #f3c122;
    padding: 0 6px;
    margin-left: 8px; }
    .count-pill.pending span {
      font-size: 10px; }

.directory-detail .client-details {
  overflow: hidden; }
  .directory-detail .client-details span,
  .directory-detail .client-details p {
    text-overflow: ellipsis;
    overflow: hidden; }

@media (min-width: 960px) {
  .search-body {
    display: flex; } }

.search-body .results {
  flex: 1;
  padding-bottom: 40px; }
  @media (min-width: 768px) {
    .search-body .results {
      padding-right: 16px; } }
  @media (min-width: 960px) {
    .search-body .results {
      flex: 0 0 75%; } }
  .search-body .results td {
    font-size: 12px; }
  .search-body .results .pending-user {
    border-left: 4px solid red; }

.search-body .ais-Pagination {
  position: sticky;
  bottom: 0;
  margin: 0 auto;
  padding: 24px 0;
  background: #fafafa;
  background: linear-gradient(0deg, #fafafa 42%, rgba(250, 250, 250, 0) 100%);
  width: calc(100vw - 40px); }
  @media (min-width: 960px) {
    .search-body .ais-Pagination {
      width: 100%; } }
  .search-body .ais-Pagination-link--selected {
    background-color: #2196f3 !important;
    color: #fff !important; }
  .search-body .ais-Pagination ul {
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none; }
    .search-body .ais-Pagination ul li {
      display: block;
      margin: 0 4px; }
      .search-body .ais-Pagination ul li span {
        visibility: hidden; }
      .search-body .ais-Pagination ul li a,
      .search-body .ais-Pagination ul li span {
        background: #ffffff;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;
        color: #2196f3;
        letter-spacing: -0.34px; }

@media (min-width: 960px) {
  .search-body .filter-bar {
    flex: 0 0 25%; } }

.refinements {
  padding: 16px;
  width: 82vw;
  max-width: 400px; }
  @media (min-width: 960px) {
    .refinements {
      width: 100%;
      max-width: 320px; } }
  .refinements .refinement {
    margin: 16px 0; }
    .refinements .refinement:first-of-type {
      margin-top: 0; }
    .refinements .refinement .force-scrollbars::-webkit-scrollbar {
      background-color: #f2f2f2; }
    .refinements .refinement .force-scrollbars::-webkit-scrollbar:vertical {
      width: 11px; }
    .refinements .refinement .force-scrollbars::-webkit-scrollbar:horizontal {
      height: 11px; }
    .refinements .refinement .force-scrollbars::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      /* should match background, can't be transparent */
      background-color: rgba(0, 0, 0, 0.5); }
    .refinements .refinement .search-bar-locations {
      padding: 0 8px;
      margin-bottom: 8px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); }

.filter-drawer {
  max-width: 300px; }

.dark-theme {
  box-shadow: none !important;
  border-radius: 8px !important;
  margin-bottom: 32px !important; }

.overflow-table {
  width: calc(100vw - 40px);
  overflow-x: auto; }
  .overflow-table tr:last-of-type td {
    border-bottom: none; }
  @media (min-width: 960px) {
    .overflow-table {
      width: auto;
      overflow: visible; } }

.honor-market {
  padding: 24px;
  margin: 0 auto;
  max-width: 1240px; }
  .honor-market .card-container {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .honor-market .card-container h6 {
      line-height: 1.2; }
    .honor-market .card-container .product-category {
      display: inline-block;
      letter-spacing: 0.5px;
      margin-top: 10px; }
      .honor-market .card-container .product-category + .product-category {
        border-left: 1px solid #ccc;
        padding-left: 8px;
        margin-left: 8px; }

.honor-market-add-product .product-image {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  max-width: 260px;
  margin: 0 auto 16px; }
  .honor-market-add-product .product-image input {
    position: absolute;
    z-index: 0;
    opacity: 0.1;
    left: 50%;
    right: 50%;
    bottom: 50%;
    top: 50%;
    width: 10px;
    height: 10px; }
  .honor-market-add-product .product-image label {
    display: block;
    border: none;
    height: 100%;
    position: relative;
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    z-index: 2;
    background-color: #fff;
    padding: 8px;
    margin-bottom: 8px; }
    .honor-market-add-product .product-image label > img,
    .honor-market-add-product .product-image label > div {
      flex: 0 0 100%;
      display: block;
      object-fit: contain;
      width: 100%;
      height: 100%;
      max-height: 150px;
      border: none;
      outline: none;
      background-color: #fff; }
    .honor-market-add-product .product-image label span {
      padding: 8px; }

.sidebar .active {
  background-color: #f2f2f2; }
  .sidebar .active .material-icons {
    color: black; }

.booking-modal,
.add-location-modal,
.add-room-modal,
.add-user-modal {
  margin: 0px !important; }
  .booking-modal .MuiDialog-paper-135,
  .add-location-modal .MuiDialog-paper-135,
  .add-room-modal .MuiDialog-paper-135,
  .add-user-modal .MuiDialog-paper-135 {
    margin: 0px !important; }
    @media (min-width: 600px) {
      .booking-modal .MuiDialog-paper-135,
      .add-location-modal .MuiDialog-paper-135,
      .add-room-modal .MuiDialog-paper-135,
      .add-user-modal .MuiDialog-paper-135 {
        margin: 20px !important; } }
  .booking-modal .booking-modal-title .close-button,
  .add-location-modal .booking-modal-title .close-button,
  .add-room-modal .booking-modal-title .close-button,
  .add-user-modal .booking-modal-title .close-button {
    position: absolute;
    right: 10px;
    top: 15px; }
  .booking-modal .mobile-stepper > div,
  .add-location-modal .mobile-stepper > div,
  .add-room-modal .mobile-stepper > div,
  .add-user-modal .mobile-stepper > div {
    flex: 1 !important; }
  .booking-modal .chip-container,
  .add-location-modal .chip-container,
  .add-room-modal .chip-container,
  .add-user-modal .chip-container {
    flex-flow: inherit; }

.cm-list {
  margin-bottom: 24px; }

.panel-list-detail {
  display: flex; }
  .panel-list-detail .list {
    -webkit-overflow-scrolling: scroll;
    height: 100%;
    overflow: auto;
    width: 100%;
    background: #fff; }
    @media (min-width: 960px) {
      .panel-list-detail .list {
        max-width: 300px;
        border-right: 1px solid rgba(0, 0, 0, 0.12); } }
  .panel-list-detail .detail {
    -webkit-overflow-scrolling: scroll;
    height: 100%;
    overflow: auto;
    flex: 1; }

.user-detail {
  margin-top: 24px;
  padding: 24px 0;
  background-color: #fff;
  outline: 1px solid rgba(0, 0, 0, 0.12);
  width: 100vw; }
  @media (min-width: 768px) {
    .user-detail {
      width: 100%;
      padding: 24px;
      min-height: 100%;
      margin: 48px auto 0;
      max-width: 1000px; } }
  .user-detail .booking-list-container {
    padding: 24px; }
    @media (min-width: 768px) {
      .user-detail .booking-list-container {
        padding: 24px 0px; } }

.pending-box {
  margin: 24px 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border-radius: 8px; }
  @media (min-width: 768px) {
    .pending-box {
      margin: 48px auto 0;
      max-width: 1000px; } }

.editor-content {
  min-height: 250px;
  max-height: 500px;
  padding: 0 24px 24px; }

.DraftEditor-editorContainer {
  line-height: 1.6; }
  .DraftEditor-editorContainer a {
    text-decoration: none;
    color: #2196f3;
    font-weight: 500; }
  .DraftEditor-editorContainer a:hover {
    border-bottom-color: #2196f3;
    border-bottom: 1px solid; }
  .DraftEditor-editorContainer p,
  .DraftEditor-editorContainer ol,
  .DraftEditor-editorContainer ul {
    margin-bottom: 1.4em; }
  .DraftEditor-editorContainer p:last-child,
  .DraftEditor-editorContainer ol:last-child,
  .DraftEditor-editorContainer ul:last-child {
    margin-bottom: 0; }
  .DraftEditor-editorContainer p:empty {
    display: none; }
  .DraftEditor-editorContainer h1:first-child,
  .DraftEditor-editorContainer h2:first-child,
  .DraftEditor-editorContainer h3:first-child,
  .DraftEditor-editorContainer h4:first-child,
  .DraftEditor-editorContainer h5:first-child,
  .DraftEditor-editorContainer h6:first-child {
    margin-top: 0; }
  .DraftEditor-editorContainer h1,
  .DraftEditor-editorContainer h2,
  .DraftEditor-editorContainer h3 {
    margin-bottom: 0.5em;
    margin-top: 1.75em;
    line-height: 1.1; }
  .DraftEditor-editorContainer ol,
  .DraftEditor-editorContainer ul {
    list-style-position: outside;
    margin-left: 16px; }
  .DraftEditor-editorContainer ol {
    list-style-type: decimal; }
  .DraftEditor-editorContainer ol ol {
    list-style-type: lower-alpha; }
  .DraftEditor-editorContainer ol ol ol {
    list-style-type: lower-roman; }
  .DraftEditor-editorContainer ol ol ol ol {
    list-style-type: lower-greek; }
  .DraftEditor-editorContainer ol ol ol ol ol {
    list-style-type: decimal; }
  .DraftEditor-editorContainer ol ol ol ol ol ol {
    list-style-type: lower-alpha; }
  .DraftEditor-editorContainer ul {
    list-style-type: disc; }
  .DraftEditor-editorContainer ul ul {
    list-style-type: circle; }
  .DraftEditor-editorContainer ul ul ul {
    list-style-type: square; }
  .DraftEditor-editorContainer ul ul ul ul {
    list-style-type: circle; }
  .DraftEditor-editorContainer ul ul ul ul ul {
    list-style-type: disc; }
  .DraftEditor-editorContainer ul ul ul ul ul ul {
    list-style-type: circle; }

.rdw-editor-toolbar .rdw-inline-wrapper,
.rdw-editor-toolbar .rdw-list-control {
  margin-right: 8px; }
  .rdw-editor-toolbar .rdw-inline-wrapper button,
  .rdw-editor-toolbar .rdw-list-control button {
    border-color: transparent;
    margin-right: -1px !important; }
    .rdw-editor-toolbar .rdw-inline-wrapper button:last-of-type,
    .rdw-editor-toolbar .rdw-list-control button:last-of-type {
      margin-right: 0px !important; }
  .rdw-editor-toolbar .rdw-inline-wrapper button.active,
  .rdw-editor-toolbar .rdw-list-control button.active {
    border-color: #2196f3 !important; }

.upload-button span:nth-child(1) {
  width: auto;
  padding: 8px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25); }

.recent-orders {
  padding: 0 24px 24px; }
  @media (min-width: 768px) {
    .recent-orders {
      padding: 0px; } }
  .recent-orders .grid-container-item {
    width: 100% !important;
    margin: 0px !important; }
  .recent-orders .total-row {
    padding-top: 16px;
    border-top: 1px dotted rgba(0, 0, 0, 0.12); }
  .recent-orders .receipt {
    padding: 16px;
    border: 2px dashed rgba(0, 0, 0, 0.12);
    font-family: monospace;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px; }
    .recent-orders .receipt .line-item {
      padding-top: 16px;
      border-top: 1px dotted rgba(0, 0, 0, 0.12); }
      .recent-orders .receipt .line-item:nth-child(1), .recent-orders .receipt .line-item:nth-child(2) {
        padding-top: 0;
        border-top: none; }
